import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  initGoogleAnalytics(): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsApiKey}`,
    );
    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${environment.googleAnalyticsApiKey}\');`;
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  pushToDataLayer(data: any): void {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(data);
  }

  setUpConfig(): void {
    this.gtag('config', environment.googleAnalyticsApiKey);
  }

  setAppVersion(): void {
    this.pushToDataLayer({
      event: 'web_release',
      value: document.querySelector('meta[name="git-sha"]')['content'],
    });
  }

  initZendesk(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendeskKey}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  get gtag() {
    return window['gtag'];
  }
}
