import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { components } from '@fc-shared/modules/global-search/components';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { globalSearchFeature } from '@fc-shared/modules/global-search/store/global-search-store';
import { GlobalSearchEffects } from '@fc-shared/modules/global-search/store/global-search-effects';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { ImageLoaderModule } from '@fc-shared/ui/image-loader/image-loader.module';
import { StatusTagComponent } from '@fc-shared/ui/status-tag/status-tag.component';
import { RouterLink } from '@angular/router';
import { ResultItemDirective } from './components/global-search-results/result-item.directive';
import { VehicleStatusBadgeModule } from '@fc-shared/ui/vehicle-status-badge/vehicle-status-badge.module';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { ThumbnailsPipeModule } from '@fc-shared/pipes/thumbnails-pipe/thumbnails-pipe.module';
import { SkeletonModule } from '@fc-shared/ui/skeleton/skeleton.module';
import { FocusOnAppearModule } from '@fc-shared/directives/focus-on-appear/focus-on-appear.module';
import { ScrollNearEndDirective } from '@fc-shared/directives/scroll-near-end.directive';
import { ImagePlaceholderComponent } from '@fc-shared/ui/image-loader/image-placeholder.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@NgModule({
  declarations: [ResultItemDirective, ...components],
  imports: [
    StoreModule.forFeature('globalSearch', globalSearchFeature.reducer),
    EffectsModule.forFeature([GlobalSearchEffects]),
    CommonModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    MatSelectModule,
    EmptyStateModule,
    ImageLoaderModule,
    StatusTagComponent,
    RouterLink,
    VehicleStatusBadgeModule,
    AvatarPlaceholderModule,
    ThumbnailsPipeModule,
    SkeletonModule,
    FocusOnAppearModule,
    ScrollNearEndDirective,
    ImagePlaceholderComponent,
    IconComponent,
  ],
})
export class GlobalSearchModule {}
