import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  viewChildren,
} from '@angular/core';
import { DialogHeaderComponent } from '@fc-shared/ui/dialog-opener/dialog-header.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { PrefixDirective } from '@fc-shared/ui/input/core/prefix.directive';
import { InputDirective } from '@fc-shared/ui/input/core/input.directive';
import { FormFieldComponent } from '@fc-shared/ui/input/core/form-field/form-field.component';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FocusOnAppearModule } from '@fc-shared/directives/focus-on-appear/focus-on-appear.module';
import { ArrowKeyNavigationDirective } from '../arrow-key-navigation.directive';
import { CustomerSelectStore } from '../store/customer-select-store';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { SkeletonModule } from '@fc-shared/ui/skeleton/skeleton.module';
import { CustomKeybindingDirective } from '../custom-keybinding.directive';
import { TagComponent } from '@fc-shared/ui/tags/tag.component';
import { IconOutlineButtonComponent } from '@fc-shared/ui/buttons/icon-outline-button.component';

const imports = [
  DialogHeaderComponent,
  IconComponent,
  PrefixDirective,
  InputDirective,
  FormFieldComponent,
  AvatarPlaceholderModule,
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  FocusOnAppearModule,
  ArrowKeyNavigationDirective,
  EmptyStateModule,
  SkeletonModule,
  CustomKeybindingDirective,
  TagComponent,
  IconOutlineButtonComponent,
];

@Component({
  selector: 'fc-dev-customer-select',
  imports: [imports],
  templateUrl: 'dev-customer-select.component.html',
  styleUrl: 'dev-customer-select.component.scss',
  providers: [CustomerSelectStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevCustomerSelectComponent {
  customerItems = viewChildren('customerItem');
  trigger = signal<CdkOverlayOrigin>(null);
  store = inject(CustomerSelectStore);

  constructor() {
    this.store.getCustomerList();
  }

  showEmployees(
    customerId: string,
    customerIndex: number,
    trigger: CdkOverlayOrigin,
  ): void {
    this.trigger.set(trigger);
    this.store.setCustomerIndex(customerIndex);
    this.store.getEmployeeList(customerId);
  }

  onCustomerSelectChange(index: number) {
    this.store.setCustomerIndex(index);
    this.trigger.set(this.customerItems()[index] as CdkOverlayOrigin);
  }
}
