import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { NewsDetails } from '@fc-core/models/news-details.model';
import { getNews } from '@fc-store/selectors';
import { Store } from '@ngrx/store';
import { setShowNews } from '@fc-store/actions';

@Component({
  selector: 'fc-news-tab',
  templateUrl: './news-tab.component.html',
  styleUrls: ['./news-tab.component.scss'],
  standalone: false,
})
export class NewsTabComponent implements AfterViewInit {
  private store = inject(Store);
  readonly news = this.store.selectSignal(getNews);

  ngAfterViewInit() {
    this.store.dispatch(setShowNews({ showNewsState: false }));
    localStorage.setItem(
      'news',
      JSON.stringify(this.news()?.map((item) => item.id)),
    );
  }
}
