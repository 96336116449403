import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { getPlatform, getVersion } from '@fc-shared/utils/data.utils';
import { StrokedButtonComponent } from '@fc-shared/ui/buttons/stroked-button.component';
import { FilledButtonComponent } from '@fc-shared/ui/buttons/filled-button.component';
import { SidePopupComponent } from '@fc-core/components/side-popup.component';
import { NotificationsModule } from '@fc-core/notifications/notifications.module';
import { MenuButtonComponent } from '@fc-shared/ui/buttons/menu-button.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { loadEmployee, setNavbarState } from '@fc-store/actions';
import { getNavbarState } from '@fc-store/selectors/ui.selector';
import { HasFeatureModule } from '@fc-shared/directives/has-feature/has-feature.module';
import { GlobalSearchService } from '@fc-shared/modules/global-search/services/global-search.service';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import {
  currentUserHasLegacyPermission,
  getCurrentEmployee,
  getCurrentUser,
  getEmployeeById,
  selectRouterParamData,
} from '@fc-store/selectors';
import {
  AuthService,
  DialogsService,
  Employee,
  MessagesService,
  User,
} from '@fc-core';
import { PermissionsService } from '@fc-core/services/permissions.service';
import { DeveloperOptionsComponent } from '../../../dialogs/developer-options/developer-options.component';
import { PwaService } from '@fc-core/services/pwa-service';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { QuickCreationComponent } from '@fc-core/components/header/quick-creation/quick-creation.component';
import { CopyToClipDirective } from '@fc-shared/directives/copy-to-clip.directive';
import { HelpCenterDialogComponent } from '@fc-core/components/header/help-center-dialog/help-center-dialog.component';
import { quickCreationNavStructure } from '@fc-core/components/header/quick-creation/quick-creation-nav.structure';
import { QuickCreationNav } from '@fc-core/components/header/quick-creation/quick-creation.nav';
import { environment } from '../../../../environments/environment';
import { getFeatures } from '@fc-store/selectors/features.selectors';
import { StatusSetApiService } from '@fc-shared/ui/status-set-dialog/status-set-api.service';
import { StatusSetService } from '@fc-shared/ui/status-set-dialog/status-set.service';
import { DevCustomerSelectComponent } from '../../../dialogs/customer-chooser-dialog/dev-customer-select/dev-customer-select.component';

@Component({
  selector: 'fc-header',
  imports: [
    RouterLink,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    StrokedButtonComponent,
    FilledButtonComponent,
    SidePopupComponent,
    NotificationsModule,
    MenuButtonComponent,
    NgTemplateOutlet,
    IconComponent,
    HasFeatureModule,
    AvatarPlaceholderModule,
    NgIf,
    IconButtonComponent,
    CopyToClipDirective,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  store: Store = inject(Store);
  router = inject(Router);
  globalSearch = inject(GlobalSearchService);
  dialogs = inject(DialogsService);
  permissions: PermissionsService = inject(PermissionsService);
  pwaService: PwaService = inject(PwaService);
  messageService = inject(MessagesService);
  authService = inject(AuthService);
  statusSetApiService = inject(StatusSetApiService);
  statusSetService = inject(StatusSetService);

  isHijacked = this.authService.tokenData?.is_hijacked;
  title: Signal<string> = this.store.selectSignal(
    selectRouterParamData('title'),
  );
  navbarState: Signal<boolean> = this.store.selectSignal(getNavbarState);
  currentEmployee: Signal<Employee> = computed(() => {
    const currentEmployee = this.store.selectSignal(getCurrentEmployee)();
    return this.store.selectSignal(getEmployeeById(currentEmployee?.id))();
  });
  currentUser: Signal<User> = this.store.selectSignal(getCurrentUser);
  features: Signal<string[]> = this.store.selectSignal(getFeatures);
  canSwitchCustomer = this.store.selectSignal(
    currentUserHasLegacyPermission(
      'accounting_customer_can_switch_customer_view',
    ),
  );
  quickActionType = this.store.selectSignal(
    selectRouterParamData('quickActionType'),
  );
  quickCreationNavs = computed(() => {
    return quickCreationNavStructure.filter((nav) => {
      if (nav?.featureKey) {
        return this.features()?.includes(nav.featureKey);
      } else {
        return true;
      }
    });
  });

  quickCreation: Signal<QuickCreationNav> = computed(() => {
    return this.quickCreationNavs()
      .map((nav) => nav.children)
      .flat()
      .find((nav) => nav.type === this.quickActionType());
  });

  logoLink =
    environment.environment === 'local' ? ['/components-overview'] : ['/'];
  platform = getPlatform();
  showNews = false;
  showEvents = false;

  openActivity() {
    this.showNews = false;
    this.showEvents = true;
  }

  openNews() {
    this.showEvents = false;
    this.showNews = true;
  }

  toggleNavbarState(): void {
    this.store.dispatch(setNavbarState({ state: !this.navbarState() }));
  }

  get version(): string {
    return getVersion();
  }

  get statusText(): string {
    const employee = this.store.selectSignal(
      getEmployeeById(this.currentEmployee().id),
    )();
    if (employee?.setstatus?.status) {
      return employee.setstatus.icon + ' ' + employee.setstatus.status;
    } else {
      return 'Update your status';
    }
  }

  openSearch(): void {
    this.globalSearch.openSearch();
  }

  openDeveloperOptions() {
    this.dialogs.openFullScreenDialog(DeveloperOptionsComponent);
  }

  openConfirmLogout() {
    this.dialogs.openConfirmDialog({
      title: `Logout?`,
      message: 'Are you sure want to logout?',
      buttonText: 'Logout',
      action: () => this.router.navigate(['/logout']),
      buttonColor: 'accent',
    });
  }

  openQuickCreationMenu() {
    this.dialogs.openMobileDialog(QuickCreationComponent);
  }

  openMobileHelp() {
    this.dialogs.openMobileDialog(HelpCenterDialogComponent);
  }

  close(): void {
    this.store.dispatch(setNavbarState({ state: false }));
  }

  openChangeCustomer() {
    if (this.canSwitchCustomer()) {
      this.dialogs.openFullScreenDialog(DevCustomerSelectComponent);
    }
  }

  openStatusSetDialog(): void {
    this.statusSetService.openStatusSetDialog(
      this.currentEmployee()?.id,
      this.currentEmployee()?.setstatus,
      loadEmployee({ id: this.currentEmployee()?.id }),
      this.statusSetApiService.setEmployeeStatus.bind(this.statusSetApiService),
      this.statusSetApiService.removeEmployeeStatus.bind(
        this.statusSetApiService,
      ),
    );
  }
}
