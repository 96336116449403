import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoreNavigationService {
  readonly isOpened: WritableSignal<boolean> = signal(false);

  toggle(): void {
    this.isOpened.set(!this.isOpened());
  }

  open(): void {
    this.isOpened.set(true);
  }

  close(): void {
    this.isOpened.set(false);
  }
}
