import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CustomerSelectComponent } from './customer-select.component';
import { AuthService } from '../../auth/auth.service';
import { AsyncPipe } from '@angular/common';
import { AuthCustomer } from '../../auth/models/auth-customer';
import { map, tap } from 'rxjs/operators';
import { FirebaseService } from '@fc-core/services/firebase.service';
import { Router } from '@angular/router';
import { User } from '@fc-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fc-user-customer-select',
  imports: [CustomerSelectComponent, AsyncPipe],
  template: `
    <fc-customer-select
      [label]="'Switch company'"
      [customers]="customers$ | async"
      [customersLoading]="customersLoading"
      (selectCustomer)="switchCustomer($event)"
    ></fc-customer-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCustomerSelectComponent implements OnInit {
  auth = inject(AuthService);
  router = inject(Router);
  firebaseService = inject(FirebaseService);
  customers$: Observable<AuthCustomer[]>;
  customersLoading = false;

  ngOnInit() {
    this.getCustomers();
  }

  switchCustomer(customer: AuthCustomer) {
    this.auth
      .selectCustomer(customer.customerId)
      .pipe(map((user: User) => this.onSuccessfulCustomerSwitch(user)))
      .subscribe();
  }

  getCustomers() {
    this.customersLoading = true;
    this.customers$ = this.auth
      .getCustomers(this.auth.token)
      .pipe(tap(() => (this.customersLoading = false)));
  }

  onSuccessfulCustomerSwitch(user: User) {
    if (user == null) return;
    this.firebaseService.init();
    this.router.navigate(['/map']);
    window.location.reload();
  }
}
