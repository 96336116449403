import { Component, inject, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { AuthService, Customer, DialogsService } from '@fc-core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAdminStore from '../../../store';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { environment } from '../../../../environments/environment';
import { UserCustomerSelectComponent } from '../../../dialogs/customer-chooser-dialog/user-customer-select.component';

@Component({
  selector: 'fc-nav-footer-accordion',
  imports: [CommonModule, MatIconModule, MatRippleModule, IconComponent],
  template: `
    <div
      class="footer-container"
      [class.closed]="!isOpen"
      [class.open]="isOpen()"
    >
      @if (authService.currentApiToken) {
        <div
          (click)="openCustomerChooserDialog()"
          [class.closed]="!isOpen()"
          [class.open]="isOpen()"
          class="customer-block"
          matRipple
        >
          <div class="customer-logo">
            <fc-icon icon="icon-business"></fc-icon>
          </div>

          <div class="customer-title">
            {{ (currentCustomer$ | async)?.name }}
          </div>
          <div class="icon">
            <fc-icon [size]="20" icon="icon-swap"></fc-icon>
          </div>
        </div>
      }

      <div
        *ngIf="getEnvironmentName !== 'production'"
        class="env-badge-container m-t-16"
      >
        {{ getEnvironmentName }}
      </div>

      <div (click)="refreshPage()" class="refresh-button">
        <div class="refresh-icon">
          <fc-icon icon="icon-refresh"></fc-icon>
        </div>
        <div class="refresh-title">Refresh</div>
      </div>
    </div>
  `,
  styleUrls: ['./nav-footer-accordion.component.scss'],
})
export class NavFooterAccordionComponent {
  isOpen = input<boolean>();
  authService = inject(AuthService);
  dialogsService: DialogsService = inject(DialogsService);
  store = inject(Store);

  currentCustomer$: Observable<Customer> = this.store.select(
    fromAdminStore.getCurrentCustomer,
  );

  get getEnvironmentName(): string {
    return environment.environment;
  }

  openCustomerChooserDialog() {
    this.dialogsService.openFullScreenDialog(UserCustomerSelectComponent);
  }

  refreshPage(): void {
    window.location.reload();
  }
}
