import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Output,
} from '@angular/core';
import { CoreNavigationService } from '@fc-core/services/core-navigation.service';

@Component({
  selector: 'fc-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  standalone: false,
})
export class NotificationContainerComponent {
  @HostBinding('class.nav-open') get opened(): boolean {
    return this.navigationService.isOpened();
  }

  @HostListener('document:mousedown', ['$event'])
  clickOut(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.notificationClose.emit();
    }
  }

  @Output() notificationClose = new EventEmitter<void>();
  private elRef = inject(ElementRef);
  readonly navigationService = inject(CoreNavigationService);
}
