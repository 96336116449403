<div class="search-results" *ngIf="searchString || hasResults">
  <div *ngIf="hasResults" fcScrollNearEnd class="search-results-list">
    <div class="results" *ngIf="showResult('vehicles', 'Vehicles')">
      <div class="results-title">Vehicles</div>
      <div class="results-list">
        <fc-vehicle-result-item
          *ngFor="
            let vehicle of results?.vehicles;
            let i = index;
            trackBy: trackByFn
          "
          [result]="vehicle"
          [search]="searchString"
          [searchCategory]="searchCategory"
          [vehicleTypeList]="vehicleTypeList$ | async"
          [showStatus]="true"
          [indexObject]="{
            itemIndex: i,
            currentIndex: searchService.selectedIndex(),
          }"
        >
        </fc-vehicle-result-item>
      </div>
    </div>

    <div class="results" *ngIf="showResult('locations', 'Locations')">
      <div class="results-title">Locations</div>
      <div class="results-list">
        <fc-location-result-item
          *ngFor="
            let location of results?.locations;
            let i = index;
            trackBy: trackByFn
          "
          [result]="location"
          [locationTypes]="locationTypes$ | async"
          [search]="searchString"
          [searchCategory]="searchCategory"
          [indexObject]="{
            itemIndex: i,
            currentIndex: searchService.selectedIndex(),
          }"
        >
        </fc-location-result-item>
      </div>
    </div>

    <div class="results" *ngIf="showResult('crm', 'CRM')">
      <div>
        <div class="results-title">CRM</div>
        <div class="results-list">
          <fc-crm-result-item
            *ngFor="
              let contact of results?.crm;
              let i = index;
              trackBy: trackByFn
            "
            [result]="contact"
            [search]="searchString"
            [searchCategory]="searchCategory"
            [companyList]="companyList$ | async"
            [indexObject]="{
              itemIndex: i,
              currentIndex: searchService.selectedIndex(),
            }"
          >
          </fc-crm-result-item>
        </div>
      </div>
    </div>

    <div class="results" *ngIf="showResult('employees', 'Employees')">
      <div class="results-title">Employees</div>
      <div class="results-list">
        <fc-employee-result-item
          *ngFor="
            let employee of results?.employees;
            let i = index;
            trackBy: trackByFn
          "
          [result]="employee"
          [search]="searchString"
          [searchCategory]="searchCategory"
          [indexObject]="{
            itemIndex: i,
            currentIndex: searchService.selectedIndex(),
          }"
        >
        </fc-employee-result-item>
      </div>
    </div>

    <ng-container *ngIf="searchLoading && hasResults">
      <div class="loading">
        <fc-skeleton width="100%" height="31px"> </fc-skeleton>
      </div>
      <div class="loading">
        <fc-skeleton width="100%" height="31px"> </fc-skeleton>
      </div>
    </ng-container>
  </div>

  <div class="results" *ngIf="searchLoading && !hasResults">
    <div class="results-list">
      <div class="loading">
        <fc-skeleton width="100%" height="31px"> </fc-skeleton>
      </div>
      <div class="loading">
        <fc-skeleton width="100%" height="31px"> </fc-skeleton>
      </div>
    </div>
  </div>

  <fc-empty-state
    *ngIf="showEmptyState"
    icon="assets/icons/empty-state_search.svg"
    subtitle="No results were found for your request."
  >
  </fc-empty-state>

  <div class="search-footer">
    <div class="search-footer-info">
      <fc-status-tag
        [color]="PlatformColors.grey400"
        [outline]="false"
        [textSize]="'small'"
      >
        <div class="footer-icons">
          <fc-icon icon="icon-arrow_upward" [size]="16"></fc-icon>
          <fc-icon icon="icon-arrow_downward" [size]="16"></fc-icon>
        </div>
      </fc-status-tag>
      Navigation
    </div>

    <div class="search-footer-info">
      <fc-status-tag
        [color]="PlatformColors.grey400"
        [outline]="false"
        [textSize]="'small'"
      >
        <div class="footer-icons">
          <fc-icon icon="icon-enter" [size]="16"></fc-icon>
        </div>
      </fc-status-tag>
      Open selected
    </div>
  </div>
</div>
