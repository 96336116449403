import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import {
  SearchCRMResult,
  SearchEmployeeResult,
  SearchLocationResult,
  SearchResultsResponse,
  SearchVehicleResult,
} from '@fc-shared/modules/global-search/models/search-results.response';

const requestMap = {
  vehicles: 'vehicleSearchRequest',
  employees: 'employeeSearchRequest',
  locations: 'locationSearchRequest',
  crm: 'crmSearchRequest',
};

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchApiService {
  private http: HttpClient = inject(HttpClient);

  search(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): Observable<SearchResultsResponse> {
    if (category !== 'all') {
      return this[requestMap[category]](searchTerm, category, offset).pipe(
        map((results) => ({ [category]: results })),
      );
    }
    return forkJoin([
      this.vehicleSearchRequest(searchTerm, category, offset),
      this.employeeSearchRequest(searchTerm, category, offset),
      this.locationSearchRequest(searchTerm, category, offset),
      this.crmSearchRequest(searchTerm, category, offset),
    ]).pipe(
      map((results) => {
        return {
          vehicles: results[0] as SearchVehicleResult[],
          employees: results[1] as SearchEmployeeResult[],
          locations: results[2] as SearchLocationResult[],
          crm: results[3] as SearchCRMResult[],
        };
      }),
    );
  }

  vehicleSearchRequest(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): Observable<SearchVehicleResult[]> {
    return this.http.get<any>(environment.apiUrl + 'api/search/quick/vehicle', {
      params: this.getSearchQueryParams(searchTerm, category, offset),
    });
  }

  employeeSearchRequest(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl + 'api/search/quick/employee',
      {
        params: this.getSearchQueryParams(searchTerm, category, offset),
      },
    );
  }

  locationSearchRequest(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl + 'api/search/quick/location',
      {
        params: this.getSearchQueryParams(searchTerm, category, offset),
      },
    );
  }

  crmSearchRequest(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'api/search/quick/crm', {
      params: this.getSearchQueryParams(searchTerm, category, offset),
    });
  }

  getSearchQueryParams(
    searchTerm: string,
    category?: string,
    offset?: number,
  ): HttpParams {
    let queryParams: HttpParams;
    queryParams = new HttpParams().set('q', searchTerm);
    queryParams = queryParams.set('limit', '10');
    if (offset) queryParams = queryParams.set('offset', offset.toString());
    return queryParams;
  }
}
