import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  model,
  output,
} from '@angular/core';

@Directive({
  selector: '[fcArrowKeyNavigation]',
  standalone: true,
})
export class ArrowKeyNavigationDirective {
  elRef = inject(ElementRef);
  selectedIndex = model<number>(0);
  disabled = input<boolean>();
  selectItem = output<number>();
  indexChange = output<number>();

  @HostListener('window:keydown', ['$event.key'])
  handleKeyDown(key: string) {
    if (this.disabled()) return;
    this.selectCustomerWithArrowKey(key);
  }

  private selectCustomerWithArrowKey(key: string) {
    const list = (this.elRef.nativeElement as HTMLElement).children;
    if (key === 'ArrowUp') {
      this.selectedIndex.set(
        this.selectedIndex() !== 0 ? this.selectedIndex() - 1 : 0,
      );
    } else if (key === 'ArrowDown') {
      this.selectedIndex.set(
        this.selectedIndex() < list.length - 1
          ? this.selectedIndex() + 1
          : this.selectedIndex(),
      );
    } else if (key === 'Enter') {
      this.selectItem.emit(this.selectedIndex());
    }
    this.scrollToSelectedCustomer();
    this.indexChange.emit(this.selectedIndex());
  }

  private scrollToSelectedCustomer() {
    const list: HTMLCollection = (this.elRef.nativeElement as HTMLElement)
      .children;
    const parentElement = this.elRef.nativeElement as HTMLElement;
    const position = (list[this.selectedIndex()] as HTMLElement)?.offsetTop;
    parentElement.scrollTo({
      top: position - parentElement.clientHeight,
      left: 100,
      behavior: 'smooth',
    });
  }
}
