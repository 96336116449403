import { Component, computed, inject, Signal } from '@angular/core';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { NgIf } from '@angular/common';
import { MatRipple } from '@angular/material/core';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { quickCreationNavStructure } from '@fc-core/components/header/quick-creation/quick-creation-nav.structure';
import { RouterLink } from '@angular/router';
import { getFeatures } from '@fc-store/selectors/features.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fc-quick-creation',
  imports: [
    IconComponent,
    NgIf,
    MatRipple,
    IconButtonComponent,
    MatDialogClose,
    RouterLink,
  ],
  template: `
    <div class="quick-creation-header align-center justify-between">
      <div class="h2">Quick creation</div>
      <fc-icon-button [mat-dialog-close]="true">
        <fc-icon icon="icon-clear"></fc-icon>
      </fc-icon-button>
    </div>
    <div class="quick-creation">
      @for (item of quickCreationNav(); track item) {
        <div class="title text-sm">{{ item.name }}</div>
        <div class="group">
          @for (child of item.children; track child) {
            <div
              (click)="dialogRef.close()"
              [routerLink]="child.path"
              matRipple
              class="item pointer"
            >
              <fc-icon *ngIf="child.icon" [icon]="child.icon" [size]="16" />
              <div class="h4 name text-secondary">{{ child.name }}</div>
            </div>
          }
        </div>
      }
    </div>
  `,
  styles: `
    @use 'var' as *;

    :host {
      padding: 12px 16px;
      overflow: hidden;

      @media (max-width: $sm-size) {
        height: $full-mobile-height;
      }
    }

    :host,
    .quick-creation {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .quick-creation {
      overflow-y: auto;
    }

    .group {
      display: flex;
      flex-direction: column;
      border: 1px solid $secondary-100;
      border-radius: 6px;
    }

    .item {
      display: flex;
      padding: 12px 16px;
      gap: 8px;
      align-items: center;
      background-color: $secondary-50;
      border-bottom: 1px solid $secondary-100;

      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  `,
})
export class QuickCreationComponent {
  store = inject(Store);
  dialogRef = inject(MatDialogRef<QuickCreationComponent>);
  features: Signal<string[]> = this.store.selectSignal(getFeatures);

  quickCreationNav = computed(() => {
    return quickCreationNavStructure.filter((nav) => {
      if (nav?.featureKey) {
        return this.features()?.includes(nav.featureKey);
      } else {
        return true;
      }
    });
  });
}
