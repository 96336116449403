import { inject, Injectable } from '@angular/core';
import { GlobalSearchActions } from '@fc-shared/modules/global-search/store/global-search-actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagesService } from '@fc-core';
import { GlobalSearchApiService } from '@fc-shared/modules/global-search/services/global-search-api.service';

@Injectable()
export class GlobalSearchEffects {
  globalSearchService: GlobalSearchApiService = inject(GlobalSearchApiService);

  constructor(
    private actions$: Actions,
    private messageService: MessagesService,
  ) {}

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActions.search),
      switchMap(({ search, category }) =>
        this.globalSearchService.search(search, category.value).pipe(
          map((searchResults) =>
            GlobalSearchActions.searchSuccess({
              searchResults,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            this.messageService.showAllFormErrorMessages(error);
            return [GlobalSearchActions.searchFailure({ error })];
          }),
        ),
      ),
    ),
  );
}
