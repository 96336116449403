<div class="event-item" matRipple>
  <div class="image-container">
    <img [src]="event.data.image" alt="event-image" />
  </div>

  <div class="event-info" *ngIf="event?.data">
    <div class="event-title h3">{{ event?.data?.body }}</div>
    <div class="event-subtitle" *ngIf="event.data.rule === 'spd'">
      Speeding <a class="active">{{ event.data.context.avgSpeed }}</a>
    </div>
  </div>

  <div class="event-time label color-label">{{ timeAgo }}</div>
</div>
