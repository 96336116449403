import { SearchCategory } from '@fc-shared/modules/global-search/models/category';

export const searchCategories: SearchCategory[] = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'Locations',
    value: 'locations',
  },
  {
    name: 'CRM',
    value: 'crm',
  },
  {
    name: 'Employees',
    value: 'employees',
  },
  {
    name: 'Vehicles',
    value: 'vehicles',
  },
];
