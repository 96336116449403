<div
  class="navigation"
  fcCustomKeybinding
  key="@"
  (keyTrigger)="openCustomerSwitch()"
  [withShift]="true"
  [withCtrl]="true"
  [disabled]="!canSwitchCustomer()"
  [ngClass]="{ open: isOpen, close: !isOpen && (isMobile$ | async) === false }"
>
  <div class="navigation-container">
    <div class="header sm-flex justify-between">
      <div class="log-container">
        <div [routerLink]="['/']" class="logo">
          <img
            class="mobile-logo"
            src="assets/img/logos/nav-mobile-logo-dark.svg"
            alt="mobile-logo"
          />
        </div>
        <div class="mobile-close-button">
          <fc-icon-button (click)="close()">
            <fc-icon icon="icon-clear"></fc-icon>
          </fc-icon-button>
        </div>
      </div>
    </div>

    <div class="nav-scroll-container">
      <div class="navigation-menu">
        <ng-container *ngFor="let nav of defaultNavigationItems; let i = index">
          <a
            class="nav-item-link"
            [routerLink]="nav.link"
            (mouseover)="onHover($event)"
          >
            <fc-navigation-item
              (click)="toggleAccordion($event, nav)"
              [isOpen]="isOpen"
              [nav]="nav"
              matTooltipPosition="right"
              [matTooltip]="
                navbarState() || nav.inner?.length ? null : nav.name
              "
            ></fc-navigation-item>

            <div class="inner-nav-float-container">
              <div class="inner-nav-float">
                <a *ngFor="let nav of nav.inner" [routerLink]="nav.link">
                  <fc-navigation-item
                    [inner]="true"
                    [nav]="nav"
                  ></fc-navigation-item>
                </a>
              </div>
            </div>

            <div *ngIf="nav.inner" class="inner-nav">
              <a *ngFor="let nav of nav.inner" [routerLink]="nav.link">
                <fc-navigation-item
                  [class.nav-open]="isOpen"
                  [isOpen]="isOpen"
                  [nav]="nav"
                ></fc-navigation-item>
              </a>
            </div>
          </a>
        </ng-container>
      </div>
      <div class="footer">
        <fc-nav-footer-accordion [isOpen]="isOpen"></fc-nav-footer-accordion>
      </div>
    </div>
  </div>
</div>

<div class="mobile-overlay" *ngIf="isOpen" (click)="close()"></div>
<fc-notification-container
  *ngIf="showNotifications"
  (notificationClose)="showNotifications = false"
></fc-notification-container>
