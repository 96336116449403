import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService, MessagesService } from '@fc-core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private messagesService: MessagesService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    request = this.checkForCypress(request);
    request = this.setTokenToRequest(request);

    return next
      .handle(request)
      .pipe(catchError((error) => this.checkResponseForErrors(error)));
  }

  private setTokenToRequest(request: HttpRequest<any>) {
    if (this.auth.token && request.url.search('/auth/login') === -1) {
      if (!request.headers.has('Authorization')) {
        request = request.clone({
          setHeaders: {
            Authorization: `JWT ${this.auth.token}`,
          },
        });
      }
    }

    return request;
  }

  private checkForCypress(request: HttpRequest<any>) {
    if (environment.cypress) {
      request = request.clone({
        setHeaders: {
          'FC-CYPRESS': `True`,
        },
      });
    }
    return request;
  }

  private checkResponseForErrors(response: HttpResponse<any>) {
    if (response instanceof HttpErrorResponse) {
      if (response.status === 403) {
        this.messagesService.openErrorMessage(response.error.detail);
      }
      if (
        response.url.search(environment.apiUrl) >= 0 &&
        response.status === 401
      ) {
        this.handleUnauthorizedError();
        return throwError(() => response);
      }
    }
    return throwError(() => response);
  }

  private async handleUnauthorizedError() {
    // try to refresh token
    const refreshedToken = await lastValueFrom(this.auth.refreshTokenRequest());
    if (refreshedToken) {
      // if refresh token request is successful, we need to refresh the page
      location.reload();
    } else {
      // if we get an error while refreshing the token, we need to logout and refresh the page
      this.auth.logout().then(() => location.reload());
    }
  }
}
