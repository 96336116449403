import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { detect } from 'detect-browser';

@Component({
  selector: 'fc-not-supported',
  template: `
    <div *ngIf="isUnsupported" class="unsupported-banner">
      <h2>
        You are using a browser that we do not support. You can download one of
        the supported browsers:
      </h2>
      <p>
        <a target="_blank" href="https://www.google.com/intl/en/chrome/"
          >Google Chrome</a
        >,
        <a target="_blank" href="https://www.mozilla.org/en-US/firefox/new/"
          >Mozilla Firefox</a
        >,
        <a target="_blank" href="https://www.microsoftedgeinsider.com/"
          >Microsoft Edge Chromium</a
        >
      </p>
    </div>
  `,
  styles: [
    `
      .unsupported-banner {
        background: #f44336;
        z-index: 999999;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        transform: translateY(-150%);
        color: #ffffff;
        text-align: center;
        box-shadow: 0 2px 4px 0 rgba(24, 33, 39, 0.3);
        animation: banner-slide-in 0.8s ease forwards;
      }

      p {
        padding: 0;
        margin: 0;
      }

      a:hover {
        text-decoration: underline;
      }

      @keyframes banner-slide-in {
        0% {
          transform: translateY(-150%);
        }

        100% {
          transform: translateY(0%);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotSupportedComponent implements OnInit {
  private browser = detect();
  private unsupportedBrowsers: string[] = ['edge', 'ie', 'opera-mini', 'bb10'];
  public isUnsupported: boolean;

  ngOnInit(): void {
    const browserName = this.browser?.name;
    if (browserName)
      this.isUnsupported =
        this.unsupportedBrowsers.join(' ').search(browserName) !== -1;
  }
}
