import { Params, RouterStateSnapshot, Data } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterState {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterState> {
  // todo we can remove this and use defaults
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root;
    let { params, queryParams, data } = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
      params = {
        ...params,
        ...route.params,
      };
      queryParams = { ...queryParams, ...route.queryParams };
      data = { ...data, ...route.data };
    }

    return {
      url: routerState.url,
      queryParams,
      params,
      data,
    };
  }
}
