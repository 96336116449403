import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { effects, reducers } from './store';
import { environment } from '../environments/environment';
import { CustomSerializer } from './store/reducers/router.reducer';
import moment from 'moment';

import { AppComponent, AuthService, InternalAppComponent } from '@fc-core';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { DeveloperOptionsComponent } from './dialogs/developer-options/developer-options.component';
import { NotSupportedComponent } from './core/not-supported/not-supported.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { AppInitService } from './core/services/app-init.service';
import { ConfirmDialogModule } from './shared/ui/confirm-dialog/confirm-dialog.module';
import { ThumbnailsPipeModule } from './shared/pipes/thumbnails-pipe/thumbnails-pipe.module';
import { AlertsModule } from '@fc-shared/ui/alerts/alerts.module';
import { HasFeatureModule } from '@fc-shared/directives/has-feature/has-feature.module';
import { FocusOnAppearModule } from '@fc-shared/directives/focus-on-appear/focus-on-appear.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { NavigationComponent } from '@fc-core/navbar/navigation/navigation.component';
import { Observable } from 'rxjs';
import { GlobalSearchModule } from '@fc-shared/modules/global-search/global-search.module';
import { NotificationsModule } from '@fc-core/notifications/notifications.module';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { HeaderComponent } from '@fc-core/components/header/header.component';
import { AppColors, AppColorsFactory } from '@fc-core/constants/colors';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { createErrorHandler } from '@sentry/angular';
import { AuthGuard } from './auth/auth-guard.service';
import { DialogHeaderComponent } from '@fc-shared/ui/dialog-opener/dialog-header.component';
import { DialogFooterComponent } from '@fc-shared/ui/dialog-opener/dialog-footer.component';
import { DialogContentComponent } from '@fc-shared/ui/dialog-opener/dialog-content.component';

export function initializeApp(appInitService: AppInitService) {
  return (): Observable<any> => {
    return appInitService.init();
  };
}

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 12);

moment.relativeTimeRounding(Math.floor);

@NgModule({
  declarations: [
    AppComponent,
    InternalAppComponent,
    DeveloperOptionsComponent,
    NotSupportedComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    HammerModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    BrowserAnimationsModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: true,
        strictActionWithinNgZone: true,
      },
    }),
    EffectsModule.forRoot(effects),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environment !== 'local',
    }),
    VehiclesModule,
    ConfirmDialogModule,
    ThumbnailsPipeModule,
    AlertsModule,
    HasFeatureModule,
    FocusOnAppearModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    NavigationComponent,
    GlobalSearchModule,
    NotificationsModule,
    HeaderComponent,
    IconButtonComponent,
    IconComponent,
    DialogHeaderComponent,
    DialogFooterComponent,
    DialogContentComponent,
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: { hideSingleSelectionIndicator: true },
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: AppColors,
      useFactory: AppColorsFactory,
    },
  ],
})
export class AppModule {}
