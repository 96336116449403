export interface SearchCategory {
  name: string;
  value: string;
  count?: number;
}

export enum SearchCategoryEnum {
  Map = 0,
  Location = 1,
  Crm = 2,
  Employee = 3,
  Vehicle = 4,
}
