<div
  class="results-value"
  (click)="openVehicle()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    @if (result.photo.scaledImg) {
      <img
        [src]="result.photo.scaledImg | thumbsUrl: 36 : 28"
        alt="vehicle-photo"
        class="vehicle-photo"
      />
    } @else {
      <fc-image-placeholder
        [thumbWidth]="36"
        [thumbHeight]="28"
      ></fc-image-placeholder>
    }

    <div class="result-desc">
      <div class="result-title" [innerHTML]="vehicleName"></div>
      <div class="result-subtitle">
        <div *ngIf="vehicleType as type" class="vehicle-type-icon-container">
          <img
            class="vehicle-type-icon"
            [src]="'/assets/icons/vehicle-types/' + type.slug + '.svg'"
            alt="type-icon"
          />
        </div>

        <div class="vehicle-info-row-title" [innerHTML]="vehicleTypeName"></div>
      </div>
    </div>
  </div>
</div>
