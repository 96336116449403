import { QuickCreationNav } from '@fc-core/components/header/quick-creation/quick-creation.nav';

export const quickCreationNavStructure: QuickCreationNav[] = [
  {
    name: 'Vehicles',
    icon: 'icon-vehicles',
    children: [
      {
        name: 'Create Vehicle',
        path: [
          'vehicles/main/vehicles/',
          { outlets: { modal: ['add-vehicle'] } },
        ],
        icon: 'icon-vehicles',
        type: 'vehicles',
      },
      {
        name: 'Create Vehicle Group',
        path: [
          'vehicles/main/vehicle-groups',
          { outlets: { modal: ['add-group'] } },
        ],
        icon: 'icon-vehicles_group',
        type: 'vehicle-groups',
      },
    ],
  },
  {
    name: 'Locations',
    icon: 'icon-location',
    featureKey: 'locations',
    children: [
      {
        name: 'Create Location',
        path: ['locations/main/location-list', 'new'],
        icon: 'icon-location',
        type: 'locations',
      },
      {
        name: 'Create Location Type',
        path: ['/locations/main/location-types/new'],
        icon: 'icon-grade',
        type: 'location-types',
      },
    ],
  },
  {
    name: 'Employees',
    icon: 'icon-group',
    children: [
      {
        name: 'Create Employee',
        path: ['/users', 'main', 'users', { outlets: { modal: ['new'] } }],
        icon: 'icon-group',
        type: 'users',
      },
      {
        name: 'Create Employee Group',
        path: [
          '/users',
          'main',
          'user-groups',
          { outlets: { modal: ['new'] } },
        ],
        icon: 'icon-user_group',
        type: 'user-groups',
      },
    ],
  },
  {
    name: 'CRM',
    icon: 'icon-crm',
    featureKey: 'crm',
    children: [
      {
        name: 'Create Company',
        icon: 'icon-crm',
        path: ['crm', 'main', 'companies', { outlets: { modal: ['company'] } }],
        type: 'companies',
      },
      {
        name: 'Create Contact',
        icon: 'icon-person',
        path: ['crm', 'main', 'contacts', { outlets: { modal: ['contact'] } }],
        type: 'contacts',
      },
    ],
  },
  {
    name: 'Live Share',
    icon: 'icon-live_share',
    children: [
      {
        name: 'Create Live Share',
        icon: 'icon-live_share',
        path: ['shared', 'personal', { outlets: { modal: ['new'] } }],
        type: 'live-share',
      },
    ],
  },
  {
    name: 'Events',
    icon: 'icon-circle',
    featureKey: 'events',
    children: [
      {
        name: 'Create Event',
        icon: 'icon-circle',
        path: ['events', 'new'],
        type: 'events',
      },
    ],
  },
];
