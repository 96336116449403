import { AfterViewInit, Component } from '@angular/core';
import { ResultItemDirective } from '@fc-shared/modules/global-search/components/global-search-results/result-item.directive';
import { highlightSearch } from '@fc-shared/utils/data.utils';
import { SearchCategoryEnum } from '@fc-shared/modules/global-search/models/category';
import { platformLinks } from '@fc-shared/modules/global-search/services/utils';
import { SearchEmployeeResult } from '@fc-shared/modules/global-search/models/search-results.response';

@Component({
  selector: 'fc-employee-result-item',
  templateUrl: './employee-result-item.component.html',
  styleUrls: ['../global-search-results.component.scss'],
  standalone: false,
})
export class EmployeeResultItemComponent
  extends ResultItemDirective<SearchEmployeeResult>
  implements AfterViewInit
{
  name = '';
  category = SearchCategoryEnum.Employee;

  ngAfterViewInit() {
    this.setName();
    this.itemLink = platformLinks.employee(this.result.id);
  }

  setName() {
    this.name = highlightSearch(
      this.search,
      `${this.result.firstName || ''} ${this.result.lastName || ''}`,
    );
  }
}
