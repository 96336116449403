import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { VehicleType } from '@fc-core';
import { MediaModel } from '@fc-vehicles/service/media-model';
import { highlightSearch } from '@fc-shared/utils/data.utils';
import { ResultItemDirective } from '@fc-shared/modules/global-search/components/global-search-results/result-item.directive';
import { Store } from '@ngrx/store';
import { selectVehicleId } from '@fc-store/actions';
import { SearchCategoryEnum } from '@fc-shared/modules/global-search/models/category';
import { platformLinks } from '@fc-shared/modules/global-search/services/utils';
import { SearchVehicleResult } from '@fc-shared/modules/global-search/models/search-results.response';

@Component({
  selector: 'fc-vehicle-result-item',
  templateUrl: './vehicle-result-item.component.html',
  styleUrls: [
    './vehicle-result-item.component.scss',
    '../global-search-results.component.scss',
  ],
  standalone: false,
})
export class VehicleResultItemComponent
  extends ResultItemDirective<SearchVehicleResult>
  implements AfterViewInit
{
  @Input() vehicleTypeList: VehicleType[];
  @Input() showStatus = false;
  vehiclePhoto: MediaModel[];
  vehicleType: VehicleType;
  vehicleTypeName = '';
  vehicleName = '';
  private readonly store: Store = inject(Store);

  ngAfterViewInit() {
    this.setVehicleType();
    this.setVehicleName();
    this.category = this.showStatus
      ? SearchCategoryEnum.Map
      : SearchCategoryEnum.Vehicle;
    this.itemLink = this.showStatus
      ? platformLinks.map(this.result.id)
      : platformLinks.vehicle(this.result.id);
  }

  setVehicleType() {
    if (!this.vehicleTypeList?.length || !this.result?.type) return;
    this.vehicleType = this.vehicleTypeList.find(
      (type) => this.result.type === type.id,
    );
  }

  setVehicleName() {
    this.vehicleTypeName = highlightSearch(
      this.search,
      `${this.result.manufacturer?.name || ''} ${this.result.model || ''}`,
    );
    this.vehicleName = highlightSearch(this.search, this.result.identifier);
  }

  openVehicle() {
    this.store.dispatch(selectVehicleId({ vehicleId: this.result.id }));
    this.onItemClick();
  }
}
