import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, replayIntegration } from '@sentry/angular';

const release = <HTMLMetaElement>document.getElementsByName('git-sha')[0];

init({
  dsn: environment.sentry,
  release: release.content,
  environment: environment.sentryEnvironment,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    // https://github.com/getsentry/sentry-javascript/issues/2292,  https://github.com/getsentry/sentry-javascript/issues/3440
    `${environment.tigerService}`,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: environment.production ? [replayIntegration()] : [],
});

if (environment.production) {
  enableProdMode();
}
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

const loadingElement = document.querySelector('.app-loading');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => setTimeout(() => loadingElement.classList.add('loaded'), 300))
  .then(() => setTimeout(() => loadingElement.remove(), 600));
