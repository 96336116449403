<div class="news-list">
  <div class="news-item" *ngFor="let newsItem of news()">
    <div class="news-subtitle subtitle color-secondary">
      {{ newsItem.meta.first_published_at | momentFormat: 'MMMM Do YYYY' }}
    </div>
    <div class="news-title h2">{{ newsItem.title }}</div>
    <img
      class="news-image"
      [src]="newsItem.preview_image.meta.download_url"
      alt="news-image"
    />
    <div class="news-content">
      {{ newsItem.description }}
      <a
        [href]="newsItem.meta.html_url"
        target="_blank"
        class="news-read-more color-main"
        >Read more</a
      >
    </div>
    <hr class="divider" />
  </div>
</div>
