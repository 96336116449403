<div class="events-tab">
  @if (notificationService.loading$ | async) {
    <div class="events-list">
      <fc-skeleton-list></fc-skeleton-list>
    </div>
  } @else {
    <div
      fcScrollNearEnd
      (nearEnd)="notificationService.loadEventHistoryItems()"
      class="events-list"
    >
      @for (item of groupedEvents$ | async; track item.dateGroup) {
        <div class="event-item-group">
          <div class="event-item-group-title color-label">
            {{ item.dateGroup }}
          </div>
          <fc-event-item
            [event]="event"
            *ngFor="let event of item.events; trackBy: trackByEvents"
          ></fc-event-item>
        </div>
      } @empty {
        <div class="empty-state">
          <fc-empty-state
            [size]="72"
            svgIcon="icon-empty_state_history"
            subtitle="You have no notifications yet"
          ></fc-empty-state>
        </div>
      }
    </div>
  }

  @if (notificationService.loadingMore$ | async) {
    <div class="events-list-load-more">
      <mat-spinner class="spinner" diameter="30" strokeWidth="4"></mat-spinner>
    </div>
  }
</div>
