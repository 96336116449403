import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@fc-core/services/permissions.service';
import { PermissionState } from '@fc-core';

@Component({
  selector: 'fc-developer-options',
  template: `
    <fc-dialog-header title="Developer options"></fc-dialog-header>
    <fc-dialog-content>
      <div class="options">
        <mat-slide-toggle
          [checked]="permission.active"
          [(ngModel)]="permission.active"
          *ngFor="let permission of permissions"
        >
          {{ permission.name }}
        </mat-slide-toggle>
      </div>
    </fc-dialog-content>

    <fc-dialog-footer
      [primaryText]="'Save'"
      (primaryClicked)="savePermissions()"
    ></fc-dialog-footer>
  `,
  styles: [
    `
      .options {
        display: flex;
        flex-direction: column;
        padding: 20px;
      }
    `,
  ],
  standalone: false,
})
export class DeveloperOptionsComponent implements OnInit {
  permissions: PermissionState[];

  constructor(private permissionsService: PermissionsService) {}

  ngOnInit() {
    this.permissions = [...this.permissionsService.userPermissions];
  }

  savePermissions() {
    this.permissionsService.updatePermissions(this.permissions);
    location.assign('/map');
  }
}
