<div
  class="results-value"
  (click)="onItemClick()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    <ng-container *ngIf="result.photo; else text">
      <img
        [src]="result.photo | thumbsUrl: 28 : 28"
        class="photo-icon"
        [class.contact]="result.name"
        alt="photo"
      />
    </ng-container>

    <ng-template #text>
      <fc-avatar-placeholder
        [size]="28"
        [shape]="result.type === 'company' ? 'circle' : 'square'"
        [color]="result.color"
        [text]="avatarPlaceholderText"
        [fontSize]="12"
      ></fc-avatar-placeholder>
    </ng-template>

    <div class="result-desc">
      <div class="result-title" [innerHTML]="name"></div>
    </div>
  </div>
</div>
