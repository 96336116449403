import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FocusOnAppearDirective } from '@fc-shared/directives/focus-on-appear/focus-on-appear.directive';

@NgModule({
  declarations: [FocusOnAppearDirective],
  imports: [CommonModule],
  exports: [FocusOnAppearDirective],
})
export class FocusOnAppearModule {}
