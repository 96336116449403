<div
  class="results-value"
  (click)="onItemClick()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    <div class="location-icon-container">
      <div [style.background-color]="locationType?.color" class="icon">
        <fc-icon
          color="white"
          [icon]="locationType?.icon"
          [size]="16"
        ></fc-icon>
      </div>
    </div>
    <div class="result-desc">
      <div class="result-title" [innerHTML]="locationName"></div>
    </div>
  </div>
</div>
